.actor-legal-wrapper {
  .content {
    padding: 20px;
    .ant-row {
      margin-bottom: 5px;
      .form-input {
        display: flex;
        flex-direction: column;
        .ant-form-item-control-wrapper {
          width: 100%;
          input {
            text-transform: none !important;
          }
        }
      }
    }
  }
}
