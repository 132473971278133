.travel-name-edit-icon {
  border: 1px solid #ee556a;
  width: 30px;
  height: 30px;
  display: flex;
  justify-content: center;
  border-radius: 50%;
  align-items: center;
  cursor: pointer;
  &:hover {
    border: 1px solid #ee556a;
    background-color: #ee556a;
    color: #ffffff;
    transition: all 0.2s cubic-bezier(0.55, 0.055, 0.675, 0.19);
  }
}
