.empty-active .editable-cell-value-wrap {
  border-color: #f5222d !important;
}
.custom-empty-active {
  border: 1px solid #f5222d;
  border-radius: 5px;
}

.custom-empty-active .ant-select-focused .ant-select-selection,
.ant-select-selection:focus,
.ant-select-selection:active {
  border: 1px solid #f5222d;
}
.manage-width .editable-cell-value-wrap {
  width: 100%;
  overflow: hidden;
}

.border {
  border: 1px solid #d9d9d9 !important;
  border-radius: 4px !important;
  padding: 5px 12px;
}
