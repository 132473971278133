.search-form {
  position: relative;
  padding: 5px 5px;
}
.fieldset-search {
  //   border: 1px solid silver;
  padding: 5px;
  border-radius: 4px;
  padding: 5px 5px;
  box-shadow: 0px 0px 30px 0px #00000033;
  transition: all 0.5s ease-in-out;
  background-color: white;
  &:hover {
    box-shadow: 0px 0px 18px 0px #0000004a;
  }
}
