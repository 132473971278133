.sample {
  .anticon {
    display: none;
  }
  .ant-modal-confirm-title {
    text-align: center;
    font-weight: 600;
  }
  .ant-modal-confirm-content {
    margin-left: 0% !important;
  }
  .ant-table-thead {
    display: none;
  }
}
.upload {
  height: 240px;
  display: flex;
  justify-content: center;
  position: absolute;
  width: 100%;
}
.block-with-text {
  display: -webkit-box;
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-line-clamp: 5;
  -webkit-box-orient: vertical !important;
}

.box-orient-vertical {
  -webkit-box-orient: vertical !important;
}

.detailedView {
  position: absolute;
  bottom: 50px;
  text-align: center;
}

.dashboardSkeleton {
  padding: 24px;
  // height: 500px;
  background-color: white;
  height: 121px;
}

.customReadMore {
  --color: black;
  a {
    color: var(--color);
    text-decoration: underline;
  }
}

.completionArea {
  background-color: white;
  // height: 100px;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: flex-start;
  padding: 10px;
  // padding-left: 20px;
  // box-shadow: 0 10px 20px rgba(0, 0, 0, 0.19), 0 6px 6px rgba(0, 0, 0, 0.23);
  span {
    font-size: 1.5rem;
    // padding-bottom: 20px;
    font-weight: bold;
  }
  .ant-progress-text {
    font-size: 1rem;
  }
}

/*progress bar style*/
// .block {
//   display: block;
// }

// .mbq {
//   margin-bottom: 0.25rem;
// }

// .mb1 {
//   margin-bottom: 1rem;
// }

.progress-container {
  margin: 5px 0px;
  background: #ccc;
  height: 10px;
  width: 100%;
  // margin-left: auto;
  // margin-right: auto;
  display: block;
  border-radius: 10px;
  // position: fixed;
  // bottom: 2%;
  // left: 50%;
  // transform: translateX(-50%);
}

.progress-bar {
  // position: absolute;
  left: 0;
  height: 10px;
  border-radius: 10px;
  background-color: #0da1d4;
  background-image: linear-gradient(90deg, #0da1d4 0%, #1dd07e 100%);
  display: block;
  transition-delay: 0.6s;
  transition: width 0.2s ease-in;
}
