.imageCategory {
  width: 100%;
  height: 100px;
  background-color: red;
}

.imageStyleContainer,
.documentStyleContainer {
  padding: 10px !important;
  position: relative;
  .imageStyle,
  .docStyle {
    width: 100%;
    height: 100px;
    // height: 200px;
    object-fit: cover;
    border-radius: 10px;
    box-shadow: 0px 0px 5px 1px #0000001c;
  }
  .imageStyle {
    width: 100%;
    // height: 150px !important;
    object-fit: cover;
  }
  .docStyle {
    object-fit: contain;
  }
  .deleteIcon {
    position: absolute;
    z-index: 1;
    top: -3px;
    right: 0px;
    background: #f86f6f;
    color: white;
    padding: 2px 6px;
    border-radius: 8px;
    // box-shadow: 0px 0px 10px 5px #0000001c;
  }
}

.desc {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.documentStyleContainer {
  position: relative;
  overflow: hidden;
  &:hover {
    .overlayComponent {
      // top: 0;
      opacity: 1;
    }
  }
  .overlayComponent {
    transition: all 0.5s cubic-bezier(0.445, 0.05, 0.55, 0.95);
    width: 100%;
    height: 100%;
    background-color: #000000a1;
    position: absolute;
    z-index: 2;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 8px;
    // top: 120%;
    opacity: 0;
    i {
      font-size: 2rem;
    }
    // top: 10px;
    // left: 10px;
    // right: 10px;
    // bottom: 10px;
  }
  .documentStyle {
    border-radius: 8px;
    padding: 10px;
    box-shadow: 0px 0px 10px 5px #0000001c;
    .docStyle {
      border-radius: 0;
      box-shadow: none;
    }
    .deleteIcon {
      top: -10px;
      right: -10px;
    }
  }
}
