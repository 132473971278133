.imageContainer {
  display: flex;
  flex-direction: column;
  width: 50%;
  align-items: center;
  align-self: flex-start;
  background-color: white;
  font-size: xx-large;
  p,
  h1 {
    margin: 0;
  }
  .text {
    text-align: center;
  }
  .movieMan {
    margin-top: 20px;
  }
}
.loginContainerWrapper {
  display: flex;
  flex-direction: column;
  width: 50%;
  justify-content: center;
  align-items: center;
  position: relative;
  align-self: flex-end;
  .loginContainer {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 50%;
    transform: translate(-50%, -50%);
    padding: 60px 30px 0px 30px;
    background-color: #ee556a;
    box-sizing: border-box;
    border-radius: 4px;
    box-shadow: none;
    .has-error .ant-form-explain,
    .has-error .ant-form-split {
      color: black;
    }
    .customButton {
      width: 100% !important;
      background-color: white;
      color: black;
      height: 35px;
    }
    .login-form {
      margin-top: 20px;
      .showPassword {
        display: flex;
        align-items: center;
        height: 20px;
        width: 20px;
        outline: none;
        border: none;
        padding: 0%;
        background-color: transparent;
      }
    }
    .line {
      width: 100%;
      position: relative;
      height: 20px;
      display: flex;
      justify-content: center;
    }
    .registerButton {
      height: 35px;
      width: 100%;
      background-color: #ee556a;
      border: 1px solid white;
      color: white;
      border-radius: 5px;
      margin-top: 30px;
      outline: none;
      box-shadow: none;
    }
    .ant-form-item-control {
      text-align: center;
    }
    .ant-input-affix-wrapper .ant-input:not(:first-child) {
      height: 35px;
    }
  }
}

.item {
  transform: translateX(-100%);
  transition: all 0.5s ease-in;
}

.item.active,
.item-enter.item-enter-active {
  transform: translateX(0%);
}

.loginParticles {
  height: 100vh;
  div {
    height: 100%;
    canvas {
      display: flex;
    }
  }
}

@media only screen and (max-width: 425px) {
  .imageContainer {
    display: none;
  }
  .loginContainerWrapper {
    width: 100%;
  }
}
@media screen and (max-width: 768px) {
  .imageContainer {
    font-size: x-large;
    .movieMan {
      height: 30%;
      width: 80%;
    }
  }
  .loginContainerWrapper {
    .loginContainer {
      width: 70%;
    }
  }
}
@media screen and (max-width: 1024px) {
  .imageContainer {
    .movieMan {
      height: 40%;
      width: 80%;
    }
  }
  .loginContainerWrapper {
    .loginContainer {
      padding: 60px 30px 0px 30px;
    }
  }
}
@media screen and (max-width: 1440px) {
  .imageContainer {
    .movieMan {
      width: 100%;
    }
  }
}
