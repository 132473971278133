.subscription {
  height: 100vh;
  width: 100vw;
  background-color: #ee556a;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  .title {
    position: absolute;
    top: 5%;
    font-size: x-large;
    font-weight: bold;
    color: white;
  }
  .plan {
    height: 70%;
    width: 20%;
    background-color: white;
    border-radius: 20px;
    display: flex;
    flex-direction: column;
    position: relative;
    overflow: hidden;
    align-items: center;
    box-shadow: 1px 1px 20px black;

    .top {
      height: 40%;
      width: 100%;
      background-color: grey;
      border-top-left-radius: 20px;
      border-top-right-radius: 20px;
      clip-path: polygon(0 0, 100% 0%, 100% 50%, 0% 100%);
      display: flex;
      flex-direction: column;
      padding-top: 10%;
      align-items: center;
      .dollar {
        position: absolute;
        top: 15%;
        left: 15%;
        font-size: xx-large;
        font-weight: bold;
        color: white;
      }
    }
    .circle {
      height: 125px;
      width: 125px;
      background-color: white;
      border-radius: 50%;
      border: 8px solid rgb(75, 75, 75);
      position: absolute;
      top: 14%;
      display: flex;
      justify-self: center;
      justify-content: center;
      align-items: center;
      font-size: x-large;
      padding-top: 10px;
    }
    .bottom {
      height: 60%;
      width: 100%;
      background-color: rgb(255, 255, 255);
      display: flex;
      justify-content: center;
      align-items: center;
      ul {
        list-style: none;
        padding: 0;
        .listText {
          font-size: medium;
        }
      }

      ul li::before {
        content: "\2022";
        color: rgb(114, 114, 114);
        font-weight: bold;
        font-size: x-large;
        display: inline-block;
        width: 1em;
        margin-left: 10px;
      }
      .startButton {
        height: 35px;
        width: 150px;
        border-radius: 4px;
        background-color: grey;
        color: white;
        outline: none;
        border: none;
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }
  }
  .plan:hover {
    height: 72%;
    width: 22%;
  }
}

@media screen and (min-width: 2560px) {
  .subscription {
    .plan {
      .circle {
        height: 200px;
        width: 200px;
      }
      .bottom {
        ul {
          .listText {
            font-size: 25px;
          }
        }
        ul li::before {
          content: "\2022";

          font-size: xx-large;
        }
        .startButton {
          margin-top: 20px;
        }
      }
    }
  }
}
