.searchBar_container {
  width: 100%;
  padding: 10px 0;
  display: flex;
  align-items: center;
  justify-content: center;
  .searchBar {
    width: 50%;
    text-align: center;
    // border: 1px solid #2da01c;
    border-radius: 50px;
    padding: 10px 0px 10px 20px;
    display: flex;
    // box-shadow: 0 0 14px -5px #2da01c;
    transition: all 0.3s cubic-bezier(0.6, -0.28, 0.735, 0.045);
    &.focused {
      box-shadow: 0 0 50px 0 rgba(82, 63, 105, 0.15);
    }

    ::-webkit-input-placeholder {
      /* Chrome/Opera/Safari */
      color: #ee556a;
    }
    ::-moz-placeholder {
      /* Firefox 19+ */
      color: #ee556a;
    }
    :-ms-input-placeholder {
      /* IE 10+ */
      color: #ee556a;
    }
    :-moz-placeholder {
      /* Firefox 18- */
      color: #ee556a;
    }

    .input {
      outline: none;
      width: 90%;
      border: none;
      font-size: 25px;
      // &:focus ~ {
      //   box-shadow: 0 0 14px -5px red;
      // }
    }
    .icon_container {
      width: 10%;
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 30px;
      color: rgba(82, 63, 105, 0.15);
    }
  }
}
