.description {
  white-space: nowrap;
  max-width: 50px !important;
  overflow: hidden;
  text-overflow: ellipsis;
}
.active-color .ant-table-column-title {
  color: red !important;
}

.fieldset-searchText {
  position: relative;
  top: -24px;
  left: 120px;
  background-color: white;
}
