@import "../../constants/ThemeConstants/_variables.scss";

.ant-drawer-header {
  border-radius: 0 !important;
  background-color: #393a3d !important;
  .ant-drawer-title,
  .ant-drawer-close {
    color: white !important;
  }
}

.listStyle {
  margin: 0;
  padding: 0;
  .list {
    list-style: none;
    // background-color: #42e0bf21;
    // padding: 10px 0;
    // margin-bottom: 5px;
    // border-radius: 20px;
    .title {
      font-weight: bold !important;
      font-size: 1rem !important;
      padding: 10px 0;
      display: block;
      color: black;
    }
    .count {
    }
  }
}
.childListStyle {
  margin: 0;
  padding: 0;
  padding-left: 8px;
  .list {
    position: relative;
    list-style: none;
    background-color: #ee556a2b;
    padding: 15px 5px;
    margin-bottom: 5px;
    // border-radius: 20px;
    border-left: 2px solid $primaryColor;
    display: flex;
    align-items: center;
    .notificationDot {
      width: 8px;
      height: 8px;
      // background: #42e0bf;
      background: tomato;
      border-radius: 50%;
      position: absolute;
      right: 5px;
      bottom: 5px;
    }
    svg {
      width: 30px;
      height: 30px;
      padding-right: 5px;
    }
    img {
      width: 50px;
      height: 50px;
      object-fit: contain;
    }
  }
}
