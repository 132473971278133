.verification {
  height: 100vh;
  width: 100vw;
  background-color: #ee556a;
  display: flex;
  justify-content: center;
  padding: 5% 20% 5% 20%;
  display: flex;
  flex-direction: column;

  p,
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    margin: 0;
  }
  .logo {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: x-large;
    background-color: white;
    align-self: center;
    height: 40px;
    width: 80px;
    color: #ee556a;
    border-radius: 4px;
  }
  .innerBox {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    //height: 70%;
    flex: 1;
    margin-top: 10px;
    background-color: white;
    border-radius: 4px;
    .mailIcon {
      display: flex;
      flex: 0.15;
    }
    .login {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 50%;
      background-color: #ee556a;
      outline: none;
      border: none;
      border-radius: 5px;
      height: 35px;
      margin-top: 20px;
      color: white;
    }
  }
}
