body {
  margin: 0;
  /* font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; */
  font-family: "Roboto", sans-serif !important;
  /* font-family: "Varela Round" !important; */
  /* font-family: 'Poppins' !important; */
  height: 100vh;
  background-color: #f9f9fc !important;
}

.delete-button {
  background-color: #e55151 !important;
  border-color: #e55151 !important;
}

/* INTEXT_COLOR_CODES starts here */
.INTSUNRISE {
  background-color: #ffb85d !important;
}
.EXTSUNRISE {
  background-color: #ff5412 !important;
}
.INT_EXTSUNRISE {
  background-color: #f58902 !important;
}

.INTDAY {
  background-color: #f3e900 !important;
}
.EXTDAY {
  background-color: #f7cb2d !important;
}
.INT_EXTDAY {
  background-color: #ffa303 !important;
}

.INTEVENING {
  background-color: #5885af !important;
}
.EXTEVENING {
  background-color: #73d9f0 !important;
}
.INT_EXTEVENING {
  background-color: #189ab4 !important;
}

.INTSUNSET {
  background-color: #fdb750 !important;
}
.EXTSUNSET {
  background-color: #bc9c22 !important;
}
.INT_EXTSUNSET {
  background-color: #bf8422 !important;
}

.INTNIGHT {
  background-color: #ada7a7 !important;
  color: white !important;
}
.EXTNIGHT {
  background-color: #757575 !important;
  color: white !important;
}
.INT_EXTNIGHT {
  background-color: #9782ad !important;
  color: white !important;
}

.links {
  height: 100%;
}

h2 {
  font-size: 14px !important;
}

h3 {
  font-weight: 300 !important;
  font-size: 17px !important;
}

h4 {
  font-weight: 300 !important;
  font-size: 17px !important;
}

.links:hover {
  transition: color 0.5s ease-in-out;
  color: #e55151 !important;
  cursor: pointer;
}

/* Handle Comma in map in row */
.sceneNumber span::after {
  /* content: ", "; */
}

.sceneNumber span:last-child::after {
  content: "";
}

.commaContainer span::after {
  content: ", ";
}
.commaContainer span:last-child::after {
  content: "";
}

/* Ant design custom styles starts here*/
/* .ant-upload-list-picture-card .ant-upload-list-item-actions .anticon-eye-o, */

.ant-badge-multiple-words {
  padding: 0 3px !important;
}
.ant-layout-sider-children {
  overflow-x: hidden;
}
.ant-table-row:hover {
  background-color: white !important;
}

.ant-table-row {
  cursor: pointer !important;
}

.ant-table-tbody td {
  position: relative !important;
  /* height: 80px !important; */
  /* height: 50px !important; */
}
.ant-table-row:hover > td {
  background-color: #e4e4e46b !important;
}
.ant-table-header {
  background-color: #d8d8d8 !important;
}

.ant-table-thead > tr > th {
  /* table head color */
  /* background-color: #d8d8d8 !important; */
  background-color: #eae9e9 !important;
  border-right: 1px solid white !important;
  /* background-color: #001529 !important;
  color: white !important; */
  border-right: 1px solid white !important;
}

.ant-table-thead > tr > th > .ant-table-header-column {
  color: #2e2a39 !important;
}

.ant-table-thead > tr > th > .ant-table-filter-icon {
  color: #2e2a39 !important;
}

.ant-table-thead > tr > th > .anticon-filter {
  color: #2e2a39 !important;
}

.ant-upload-list-picture-card .ant-upload-list-item-actions .anticon-delete {
  display: none !important;
}
.registerCompanyModal .ant-modal-close {
  display: none;
}
.errorModal .ant-modal-close {
  display: none;
}

.ant-menu.ant-menu-dark .ant-menu-item-selected,
.ant-menu-submenu-popup.ant-menu-dark .ant-menu-item-selected {
  /* background-color: #2d3848 !important; */
  color: black !important;
}
.ant-menu-item-selected {
  font-weight: bold !important;
  font-size: 30px;
  border-left: 3px solid black;
}
.ant-menu-item-selected > span {
  color: black;
}
.ant-menu-item {
  margin: 0 !important;
  height: 60px !important;
  display: flex !important;
  align-items: center !important;
  justify-content: flex-start !important;
  background-color: #eceef1;
}

.ant-layout-sider-children {
  background-color: #eceef1;
  margin: 0;
}

.ant-table-thead
  > tr
  > th.ant-table-column-has-actions.ant-table-column-has-filters {
  text-align: center;
  text-transform: uppercase !important;
}

.ant-table-column-title {
  text-transform: uppercase;
  /* text-transform: capitalize; */
}

.location-modal .ant-modal-body {
  padding: 0 !important;
}
.location-modal .ant-modal-close-x {
  display: none;
}
.ant-table-column-title {
  font-weight: 600;
}
.primary-font-color {
  color: #ee556a !important;
}

.text-center {
  text-align: center;
}
.ant-input-disabled {
  color: #000 !important;
  /* background-color: #e4e4e4 !important; */
  border: none !important;
  /* border-bottom: 1px solid #2da01c !important; */
  /* width: max-content !important; */
}

.ant-back-top {
  bottom: 20px !important;
  right: 50px !important;
}

.sceneno-tag {
  border-radius: 10px !important;
  height: 25px !important;
  min-width: 25px !important;
  border: none !important;
  box-shadow: 0px 0px 10px 0px #00000033;
  color: black !important;
  margin-bottom: 2px !important;
}

.sceneno-tag a {
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
  height: 100%;
  color: black !important;

  /* filter: invert(1) grayscale(1) contrast(9); */
  /* mix-blend-mode: difference; */
}

/* width */
/* .ant-table-scroll-position-left ::-webkit-scrollbar {
  width: 8px;
} */

/* Track */
/* .ant-table-scroll-position-left ::-webkit-scrollbar-track {
  background: #f1f1f1;
} */

/* Handle */
/* .ant-table-scroll-position-left ::-webkit-scrollbar-thumb {
  background: #2da01c;
  border-radius: 10px;
  height: 50px;
} */

/* Handle on hover */
/* .ant-table-scroll-position-left ::-webkit-scrollbar-thumb:hover {
  background: #2da01c;
} */

/* .image-gallery-thumbnail img {
  width: 100px;
  height: 100px;
}

.image-gallery-slide img {
  width: 100%;
  height: 400px;
} */

/* width */
.ant-table-body::-webkit-scrollbar {
  width: 15px;
  height: 10px;
  border-radius: 6px;
}

/* Track */
.ant-table-body::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
.ant-table-body::-webkit-scrollbar-thumb {
  background: #ee556a;
  border-radius: 1px;
}

/* Handle on hover */
.ant-table-body::-webkit-scrollbar-thumb:hover {
  background: #ee556a;
}

.small-upload-image {
  display: flex;
  justify-content: center;
}

.art-set .ant-table-thead > tr > th,
.ant-table-tbody > tr > td {
  padding: 3px 16px;
}

.small-upload-image .ant-upload.ant-upload-select-picture-card {
  height: 50px;
  width: 50px;
}

.small-upload-image .ant-upload-text {
  display: none;
}

.customUpload .ant-upload-list-item-error {
  display: none;
}
.editable-cell {
  position: relative;
  min-height: 50px;
}

.editable-cell-value-wrap {
  height: 32px;
}

.editable-cell-value-wrap {
  padding: 5px 12px;
  cursor: pointer;
  /* border: 1px solid #fff !important; */
  border-radius: 4px;
}

/* .editable-row:hover {
  background-color: #fff !important;
}

.ant-table-thead > tr.ant-table-row-hover:not(.ant-table-expanded-row) > td,
.ant-table-tbody > tr.ant-table-row-hover:not(.ant-table-expanded-row) > td,
.ant-table-thead > tr:hover:not(.ant-table-expanded-row) > td,
.ant-table-tbody > tr:hover:not(.ant-table-expanded-row) > td {
  background-color: #fff;
}

.ant-table-thead > tr > th, .ant-table-tbody > tr > td {
  padding: 0px 16px !important;
} */

.ant-layout-sider-children {
  max-height: calc(100vh - 55px);
  overflow-y: scroll;
}
.ant-layout-sider {
  background: #eceef1 !important;
}

/* width */
.ant-layout-sider ::-webkit-scrollbar {
  width: 8px !important;
}

/* Track */
.ant-layout-sider ::-webkit-scrollbar-track {
  /* background: #f1f1f1; */
}

/* Handle */
.ant-layout-sider ::-webkit-scrollbar-thumb {
  background: #2da01c;
  border-radius: 10px;
  max-height: 10% !important;
}

/* Handle on hover */
.ant-layout-sider ::-webkit-scrollbar-thumb:hover {
  background: #2da01c;
}

.sceneCircle {
  border: 1px solid #ccc;
  padding: 2px 4px;
  border-radius: 50%;
  margin-left: 5px;
}

.ant-table-filter-dropdown {
  /* position: absolute !important; */
}

.editable-row:hover .editable-cell-value-wrap {
  /* padding: 4px 11px; */
}

.editable-cell {
  position: relative;
  min-height: 50px;
}
.editable-cell-value-wrap {
  height: 32px;
}
.editable-cell-value-wrap {
  padding: 5px 12px;
  cursor: pointer;
  border: 1px solid #d9d9d9;
  border-radius: 4px;
  overflow: auto;
}

.react-resizable {
  position: relative;
  background-clip: padding-box;
}

.react-resizable-handle {
  position: absolute;
  width: 10px;
  height: 100%;
  bottom: 0;
  right: -5px;
  cursor: col-resize;
  z-index: 1;
}

/* Ant design custom styles ends here*/

/* code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
} */

/* Pagination styles starts here */

.pagination > li {
  display: inline-block;
  padding-left: 0;
}
.pagination > li {
  list-style: none;
  border: 0.9px solid;
}
.pagination > li > a,
.pagination > li > span {
  position: relative;
  float: left;
  padding: 6px 12px;
  line-height: 1.42857143;
  text-decoration: none;
  color: #2c689c;
  background-color: #fff;
  border: 1px solid #ddd;
  margin-left: -1px;
}

.pagination > li.active > a {
  color: #000;
  background-color: #ff5656;
  border-color: #ff5656;
}
.slick-prev {
  left: -20px !important;
  z-index: 0;
  font-size: 26px !important;
}
.slick-prev::before {
  content: "←";
  color: #ff5656;
  /* font-size: 30px; */
}
.slick-next {
  right: -20px !important;
  z-index: 1;
}
.slick-next::before {
  content: "→";
  color: #ff5656;
  /* font-size: 30px; */
}

.sample {
  cursor: pointer;
  box-sizing: border-box;
  background-color: white;
  display: flex !important;
  justify-content: center !important;
  cursor: auto !important;
}
.sample .textArea {
  padding: 4px;
  margin: 10px 0 10px 0;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer !important;
  transition: all 0.2s ease-in-out;
}

.slick-track {
  display: flex;
  align-items: center;
}

.active {
  border: 1px solid #ff5656;
  /* transition: all 0.1s ease-in-out; */
  transform: scale(1.2);
  color: #ff5656;
  font-weight: bold;
}
.active-new {
  border: 1px solid #ff5656;
  background-color: #ff5656;
  border-radius: 10px !important;
  transition: all 0.1s ease-in-out;
  padding: 2px !important;
  transform: scale(1.2);
  color: #fff;
  font-weight: bold;
  margin: 5px 0 !important;
}

.boxShadow {
  transition: all 0.5s ease-in-out;
}

.boxShadow:hover {
  box-shadow: 0 0 9px 0px #00000057;
}

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.view-more {
  color: #2da01c;
  text-decoration: none;
  background-color: transparent;
  outline: none;
  cursor: pointer;
}

.custom-slider .ant-menu-inline-collapsed > .ant-menu-item .anticon,
.ant-menu-inline-collapsed
  > .ant-menu-item-group
  > .ant-menu-item-group-list
  > .ant-menu-item
  .anticon,
.ant-menu-inline-collapsed
  > .ant-menu-item-group
  > .ant-menu-item-group-list
  > .ant-menu-submenu
  > .ant-menu-submenu-title
  .anticon,
.ant-menu-inline-collapsed
  > .ant-menu-submenu
  > .ant-menu-submenu-title
  .anticon {
  display: flex;
}
