.customPicker {
  .ant-calendar-picker-input {
    border-radius: 0 !important;
    box-shadow: 0 0 14px 0px #00000030 !important;
  }
}

.accountOverviewBarChart {
  div {
    div {
      svg {
        g {
          g {
            cursor: pointer;
          }
        }
      }
    }
  }
}
