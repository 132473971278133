%password-wrapper {
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  background-color: rgb(255, 255, 255);
  border-radius: 4px;
}
%eye-icon {
  height: 15px;
  width: 15px;
}
%eye-button {
  display: flex;
  align-items: center;
  height: 20px;
  width: 20px;
  outline: none;
  border: none;
  padding: 0%;
  background-color: transparent;
}
.registration {
  display: flex;
  flex-direction: row;
  height: 100vh;
  width: 100%;
  background-color: blue;

  p,
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    margin: 0;
  }
  .left {
    display: flex;
    flex-direction: column;
    flex: 0.5;
    height: 100%;
    background-color: white;
    align-self: flex-start;
    align-items: center;

    p,
    h1 {
      margin: 0;
    }
    .text {
      text-align: center;
    }
  }
  .right {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    flex: 0.5;
    height: 105%;
    background-color: #ee556a;
    align-self: flex-end;
    padding-top: 50px;
    .aceLogo {
      color: #595959;
      height: 60px;
      width: 250px;
      background-color: white;
      border-radius: 4px;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 25px;
      font-weight: bold;
    }
    .formContainer {
      display: flex;
      flex-direction: column;
      height: fit-content;
      width: 50%;
      align-items: center;

      .formItem {
        display: flex;
        flex-direction: column;
        width: 100%;
        margin-top: 5px;
        justify-content: space-between;
        align-items: center;

        label {
          display: flex;
          align-self: flex-start;
          font-size: small;
          color: white;
        }
        .ant-input-affix-wrapper .ant-input:not(:last-child) {
          height: 35px;
        }

        input:not([type="range"]) {
          height: 35px;
        }
        .passwordWrapper {
          @extend %password-wrapper;

          .eyeButton {
            @extend %eye-button;
            .eyeIcon {
              @extend %eye-icon;
            }
          }
        }
        .inputField {
          height: 35px;
          width: 100%;
          padding: 10px;
          display: flex;
          border-radius: 4px;
          box-shadow: none;
          border: none;
          outline: none;
        }
      }
      .errMsg {
        font-size: smaller;
        display: flex;
        align-self: flex-end;
        color: rgb(0, 0, 0);
      }
      .signUp {
        height: 35px;
        width: 100%;
        background-color: white;
        border: none;
        outline: none;
        border-radius: 4px;
        outline: none;
        color: black;
        margin-top: 25px;
      }
    }
  }
}

@media screen and (max-width: 768px) {
  .registration {
    .right {
      .formContainer {
        .formItem {
          label {
            font-size: smaller;
          }
        }
        .signUp {
          width: 100%;
        }
      }
    }
  }
}
@media screen and (max-width: 425px) {
  .registration {
    .left {
      display: none;
    }
    .right {
      flex: 1;
    }
  }
}
@media screen and (max-width: 320px) {
  .registration {
    .right {
      .formContainer {
        .formItem {
          .inputField {
            width: 100%;
          }
        }
      }
    }
  }
}
