.card {
  border: none;
  text-align: left;
  max-height: 100px;
  min-height: 100px;
  overflow: hidden;
  padding: 5px;
  padding-top: 10px;
  border-radius: 6px;
  box-shadow: 0 1px 4px -1px rgba(0, 0, 0, 0.2);
  &:hover {
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
    transition: all 0.2s cubic-bezier(0.55, 0.055, 0.675, 0.19);
  }
  h5 {
    font-size: 13px;
  }
}

.btn {
  border: none;
  height: 150px;
  width: 100%;
  color: white !important;
  cursor: pointer;
  font-size: 20px;
  transition: 0.5s all ease-in-out;
  background-size: 200%;
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: space-around;
  &:hover {
    background-position: right;
  }
  .details_container {
    width: 80%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    .title_amount {
      display: flex;
      flex-direction: column;
      align-items: center;
      padding: 10px;
      .title {
        font-size: 20px;
      }
    }
  }
  .image_container {
    width: 20%;
  }
}

.btn-img {
  background-image: linear-gradient(to left, #001529, #ee556a);
}

.skeletonCard {
  //   height: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 10px;
  .card {
    height: 150px !important;
    max-height: 150px;
    display: flex;
    align-items: center;
    justify-content: center;

    // box-shadow: 0 0 9px 0px;
    // border-radius: 8px;
  }
}

.travelCard {
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24) !important;
  transition: all 0.3s cubic-bezier(0.25, 0.8, 0.25, 1) !important;
  border-radius: 0 !important;
  &:hover {
    box-shadow: 0 14px 28px rgba(0, 0, 0, 0.25), 0 10px 10px rgba(0, 0, 0, 0.22) !important;
  }
}
