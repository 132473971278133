.rejected {
  background-color: #f0f0f0;
}
.confrimedCard {
  position: absolute;
  top: 13px;
  left: 17px;
  font-size: 22px;
  color: #2da01c;
}

.text-wrap{
  word-break: break-word; 
  margin-bottom: 10px;
  display: block;
  /* max-width: 170px;
  width: 170px; */
  margin-left: 16px;
  margin-top: -1px;
}
.locationIcon{
  float: right;
  margin-top: -43px;
}
.locationStatus{
  margin-left: 30px;
}

