.rejected {
  background-color: #f0f0f0;
}
.confrimedCard {
  position: absolute;
  top: 13px;
  left: 17px;
  font-size: 22px;
  color: #2da01c;
}
