.text-left {
  text-align: left;
}

@media (min-width: 768px) {
  .text-right {
    text-align: right;
  }
}

@media (min-width: 300px) and (max-width: 767px) {
  .text-right {
    text-align: left;
  }
}

.pl-15 {
  padding-left: 15px;
}

.pt-3 {
  padding-top: 3px;
}

.ml-15 {
  margin-left: 15px;
}

.mb-25 {
  margin-bottom: 25px;
}

.pb-10 {
  padding-bottom: 10px;
}

.pr-10 {
  padding-right: 10px;
}

.font-600 {
  font-weight: 600;
}

.w-100 {
  width: 100%;
}

.p-5 {
  padding: 5px;
}

.ant-tabs-nav-scroll {
  padding-top: 0px;
}

.card {
  border: 1px solid #e3e3e3;
  text-align: left;
  max-height: 100px;
  min-height: 100px;
  overflow: hidden;
  padding: 5px;
  padding-top: 10px;
}

.card .vertical-center {
  height: -webkit-fill-available;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}

.card img {
  height: 60px;
  width: 60px;
  margin-top: 6px;
  object-fit: cover;
}
/*# sourceMappingURL=ScriptOverview.css.map */
