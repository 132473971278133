.slider_style {
  width: 4em;
  height: 100%;
  position: fixed;
  z-index: 2;
  transition: all 0.5s ease;
  box-shadow: 13px -2px 27px -12px rgba(41, 50, 66, 0.075) !important;
  overflow-y: scroll;
  overflow-x: hidden;
  padding-bottom: 100px;
  background-color: white;
  &::-webkit-scrollbar-track {
    // -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    border-radius: 10px;
    background-color: #f5f5f5;
  }
  &::-webkit-scrollbar {
    width: 1px;
    background-color: #f5f5f5;
  }
  &::-webkit-scrollbar-thumb {
    border-radius: 4px;
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    background-color: #d8dce6;
  }
  &.home {
    max-width: max-content !important;
    height: 100%;
    background-color: white;
  }
  &:hover {
    .ant-menu {
      .slider_name {
        opacity: 1;
      }
    }
  }
  .ant-menu {
    border: none;
    min-width: 13.5em;

    li {
      box-shadow: inherit !important;
      background-color: white !important;
    }
    & .ant-menu-item-selected {
      background-color: #ee556a1f !important;
      border-left: 5px solid #ee556a;
    }
    .slider_icon {
      margin: 0;
      svg {
        width: 2em;
        height: 2em;
        margin-right: 10px;
      }
    }
    .slider_name {
      transition: all 0.5s ease;
      transition-delay: 0.4s;
      opacity: 0;
      display: block;
      width: 150px;
    }
  }
  &:hover {
    width: 14em !important;
  }
}

.schedular-table {
  // height: 80px !important;
  .ant-table-tbody {
    td {
      height: 0px !important;
    }
  }
}

.box-shadow {
  box-shadow: 0px 0px 19px -3px #00000033;
}

.script-table-header {
  display: flex;
  background-color: transparent;
  height: 40px;
  // position: absolute;
  transition: all 0.5s cubic-bezier(0.445, 0.05, 0.55, 0.95);
  // z-index: 1;
  // top: -53px;
  width: 100%;
  padding-right: 17px;
  // box-shadow: 0px 0px 19px -3px #00000033;
  &.show {
    top: 0px;
    z-index: 1;
  }
  .table-headings {
    display: flex;
    align-items: center;
    justify-content: start;
    border-right: 1px solid white;
    background-color: #d8d8d8;
    padding: 4px;
    border-spacing: 2px;
    font-weight: bold;
    font-size: 12px;
    &:nth-child(1) {
      width: 10.1% !important;
    }
    &:nth-child(2) {
      width: 25% !important;
    }
    &:nth-child(3) {
      width: 10% !important;
    }
    &:nth-child(4) {
      width: 10% !important;
    }
    &:nth-child(5) {
      width: 34.9% !important;
    }
    &:nth-child(6) {
      width: 10% !important;
    }
  }
}

.script-table {
  .ant-table-thead {
    display: none;
  }
}

.mt-25 {
  margin-top: 25px;
}

.mb-25 {
  margin-bottom: 25px;
}

.mb-14 {
  margin-bottom: 14px;
}

.ant-select-selection--multiple .ant-select-selection__choice {
  background-color: #ee556a !important;
  color: white !important;
  border: none !important;
  border-radius: 4px !important;
}

.ant-select-dropdown-menu-item-group-title {
  // height: 32px;
  padding: 0 12px;
  color: rgba(0, 0, 0, 0.45);
  font-size: 18px;
  color: white;
  line-height: 32px;
  background-color: #ee556a;
  word-wrap: none;
}

.card-style {
  position: relative;
  max-height: 300px;
}

.projectImage {
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  object-fit: cover;
  position: absolute;
  transform: scale(2);
  animation: imageAnimation 10s infinite alternate ease-in-out;
}

.image-overlay {
  width: 100%;
  height: 100%;
  background-color: black;
  position: absolute;
  top: 0;
  opacity: 0.5;
}

.project-detail {
  // position: absolute;
  top: 0;
  width: 100%;
  margin-left: 10px;
  margin-right: 10px;
  overflow: hidden;
  .project-name {
    font-weight: bold;
    font-size: 1.3rem;
    color: white;
  }
  .project-description {
    font-size: 0.8rem;
    color: white;
    text-align: justify;
    a {
      color: white;
      text-decoration: underline;
    }
    // display: block;
  }

  .project-detail-label {
    font-weight: bold;
    margin-right: 10px;
    // color: #ee556a;
    color: black;
    &::after {
      // content: ":";
    }
    & ~ span {
      color: white;
    }
  }
  .product-detail-value {
    color: white;
  }
  .project-script-upload {
    background-color: transparent;
    border: 0;
    .upload-label {
      color: white;
    }
  }
}

.dashboard-statistics-container {
  width: 100%;
  background-color: white;
  // border-radius: 4px;
  overflow: hidden;
  box-shadow: 0 0 13px 0 rgba(82, 63, 105, 0.05);
  .dashboard-statistics-container-header {
    background-color: #393a3d;
    border-bottom: 1px solid #ebedf2;
    height: 35px;
    padding-left: 25px;
    .dashboard-statistics-head {
      display: flex;
      align-items: center;
      height: 100%;
      .dashboard-statistics-head-title {
        margin: 0;
        padding: 0;
        font-size: 16px;
        font-weight: 500;
        // color: #48465b;
        color: white;
      }
    }
  }
  .dashboard-statistics-container-body {
    background-color: white;
    height: 370px;
    // padding: 20px;
    padding: 10px;
    font-size: 12px;

    .body-container {
      max-height: 325px;
      overflow-y: auto;
      .list-container {
        list-style: none;
        margin: 0;
        padding: 0;
        max-height: 450px;
        overflow-y: auto;
        .table-body {
          height: max-content;
        }
        th {
          padding: 5px;
        }
        .list {
          border-bottom: 1px dashed #ebedf2;
          // height: 40px;
          &:last-child {
            border: none;
          }

          td {
            font-weight: 500;
            color: #74788d;
            padding-left: 5px;
            padding-right: 5px;
          }
        }
      }
    }
  }
}

.dashboardTemplateSkeletonContainer {
  padding-left: 10px;
  padding-right: 10px;
  margin-bottom: 20px;
  .dashboardTemplateSkeleton {
    background-color: white;
    border-radius: 4px;
    .dashboardTemplateSkeletonHead {
      padding-left: 25px;
      padding-top: 20px;
      height: 60px;
      background-color: #d3d3d3;
      box-shadow: 0px 1px 0px 0px #eeeeee;
    }
    .dashboardTemplateSkeletonBody {
      padding: 10px 20px;
      height: 370px;
      .list {
        height: 50px;
        padding: 15px 0;
        &:nth-child(1) {
          height: 41px;
          padding: 10px 0;
        }
      }
    }
  }
}

.custom-button-style {
  --color: #ee556a;
  display: inline-block;
  background-color: transparent;
  border-radius: 4px;
  border: 1px solid var(--color);
  padding: 3px;
  cursor: pointer;
  color: var(--color);
  transition: all 0.5s ease;
  &:hover {
    background-color: var(--color);
    color: white;
  }
}

@keyframes imageAnimation {
  0% {
    transform: scale(1) rotate(0deg);
  }
  100% {
    transform: scale(1.2) rotate(1deg);
  }
}

.ant-drawer-wrapper-body {
  &::-webkit-scrollbar-track {
    // -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    border-radius: 10px;
    background-color: #f5f5f5;
  }
  &::-webkit-scrollbar {
    width: 4px;
    background-color: #f5f5f5;
  }
  &::-webkit-scrollbar-thumb {
    border-radius: 4px;
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    background-color: #d8dce6;
  }
}

.accProductionScroll {
  .ant-select-selection-selected-value {
    font-size: 11px !important;
    text-transform: capitalize !important;
  }
  .ant-table-column-title {
    font-size: 11px !important;
  }
  td,
  input,
  textarea,
  button {
    font-size: 11px;
  }
  font-size: 11px !important;
  overflow: auto;
  &::-webkit-scrollbar-track {
    // -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    border-radius: 10px;
    background-color: #f5f5f5;
  }
  &::-webkit-scrollbar {
    height: 4px;
    background-color: #f5f5f5;
  }
  &::-webkit-scrollbar-thumb {
    border-radius: 4px;
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    background-color: #d8dce6;
  }
}

.map-char-with-scene {
  &::-webkit-scrollbar-track {
    // -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    border-radius: 10px;
    background-color: #f5f5f5;
  }
  &::-webkit-scrollbar {
    width: 4px;
    background-color: #f5f5f5;
  }
  &::-webkit-scrollbar-thumb {
    border-radius: 4px;
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    background-color: #d8dce6;
  }
}

.ant-table-pagination {
  // padding-right: 10px !important;
}

.costume_makeup_table {
  textarea {
    width: 500px !important;
    text-align: left !important;
  }
  .costume_makeup_table_description {
    display: flex;
    justify-content: center;
    .editable-cell-value-wrap {
      text-align: left;
      overflow: hidden;
      text-overflow: ellipsis;
      word-wrap: break-word;
      height: max-content !important;
      max-height: 99px;
      min-height: 33px;
      max-width: 500px !important;
      min-width: 500px !important;
      // margin: 0;
    }
  }
}

.ant-collapse {
  border: none !important;
}
.accordion_style {
  background-color: #797979;
  border: none;
  box-shadow: 0 0 13px 0 #00000040 !important;
  margin-bottom: 10px;
  .ant-collapse-header {
    color: white !important;
  }
  .ant-collapse-arrow {
    display: none !important;
  }
  .ant-collapse-content {
    border-radius: 0 !important;
  }
}

.createModal {
  .ant-modal-content {
    border-radius: 0;
    .ant-modal-close-x {
      color: white;
    }
    .ant-modal-header {
      border-radius: 0;
      background-color: #393a3d;
      .ant-modal-title,
      .ant-modal-close-x {
        color: white;
      }
    }
  }
}

.insertModal {
  .ant-modal-content {
    border-radius: 0;
    height: 540px;
    .ant-modal-close-x {
      color: white;
    }
    .ant-modal-header {
      border-radius: 0;
      background-color: #393a3d;
      .ant-modal-title,
      .ant-modal-close-x {
        color: white;
      }
    }
  }
}
.episodeModal {
  .ant-modal-content {
    border-radius: 0;
    max-height: 540px;
    overflow-y: scroll;
    margin-bottom: 20px;
    .ant-modal-close-x {
      color: white;
    }
    .ant-modal-header {
      border-radius: 0;
      background-color: #393a3d;
      .ant-modal-title,
      .ant-modal-close-x {
        color: white;
      }
    }
  }
}

.floatRight {
  float: right;
}

.custom-tag {
  cursor: pointer !important;
  font-size: 0.6rem;
}

.noEvent {
  pointer-events: none !important;
  cursor: no-drop !important;
}

.link-style {
  position: relative;
  height: 100%;
  display: block;
  .buttonArea {
    position: absolute;
    top: calc(50% - 20px);
    right: 10px;
    z-index: 2;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    width: 100px;
    .edit_icon,
    .delete_icon {
      width: 40px;
      height: 40px;
      background-color: white;
      display: flex;
      align-items: center;
      justify-content: center;
      color: black;
      border-radius: 50%;

      box-shadow: 7px 5px 19px -1px #00000069;
    }
    .delete_icon {
      background-color: #ea4848;
      color: white;
    }
  }
}
.statusTag,
.dateTag,
.EquipmentTag {
  color: white;
  border-radius: 15px;
  padding: 3px 10px;
  border: none;
  background-color: #ee556a;
}

.dateTag {
  margin: 0;
}

.CONFIRMED,
.COMPLETED,
.ANIMAL,
.JUNIOR_ARTIST,
.Director,
.ArtDirector,
.Costumer,
.Catering,
.NEFT {
  background-color: #79c447;
}
.TO_BE_CONFIRMED,
.IN_PROGRESS,
.LOCK,
.DATE,
.MAKEUP,
.STUNT,
.MAIN_ACTOR,
.MAIN,
.SuperAdmin,
.ProjectAdmin,
.SUPPORTING_ACTOR,
.LOCATION,
.ACTOR_STAFF,
.VENDOR,
.LOCATION_WORK_RENT,
.JUNIOR,
.INPROGRESS,
.RTGS {
  background-color: #67c3ef;
}
.REJECTED,
.NOT_COMPLETED,
.COSTUME,
.SONG,
.ExecutiveProducer,
.ProductionManager,
.PRODUCTION,
.CHEQUE {
  background-color: #ff5555;
}
.NEGOTIATION,
.ACTUAL_LOCATION,
.ACTOR_CONVEYANCE,
.EquipmentTag,
.EQUIPMENT,
.VFX,
.SUPPORTING,
.DOP,
.ART_SET,
.TRANSPORT,
.CASH {
  background-color: #fabb3d;
}

#actuallocation {
  // margin: 4px 0;
  // max-height: 30px !important;
  // min-height: 30px !important;
  .css-yk16xz-control {
    min-height: 30px !important;
    max-height: 30px !important;
  }
}

.ant-btn-danger {
  background-color: #f5222d !important;
  color: white !important;
}

.ant-calendar-picker-clear {
  opacity: 1 !important;
  display: block;
}

.ant-table-thead > tr > th {
  // background-color: #e8e8e8 !important;
}
.ant-table-thead > tr > th .ant-table-header-column {
  // padding: 6px 0px;
  font-size: 12px;
}

.ant-table-bordered .ant-table-thead > tr > th {
  padding: 5px;
}
.ant-table-bordered .ant-table-thead > tr > td {
  padding: 2px !important;
}

.art-set .ant-table-thead > tr > th,
.ant-table-tbody > tr > td {
  padding: 4px !important;
}

.ant-table-bordered .ant-table-thead > tr > th,
.ant-table-bordered .ant-table-tbody > tr > td {
  padding: 5px !important;
}

th.take-td-full {
  padding: 4px 4px !important;
  height: -webkit-fill-available;
}

.totalAmountBox,
.spentAmountBox {
  margin-left: 10px;
  width: 20px;
  height: 10px;
}

.totalAmountBox {
  background-color: #48465b;
}
.spentAmountBox {
  background-color: #ee556a;
}

.doughnutArea {
  cursor: pointer;
  transition: all 0.5s ease-in-out;
  border: 1px solid #d8dce6;
  margin: 10px;
  padding: 10px;
  border-radius: 0px;
  &.activeChart {
    border-color: #ee556a;
    background-color: #ee556a;
    box-shadow: 2px 9px 33px -8px rgba(41, 50, 66, 0.69);
    border-radius: 10px;
    div {
      transition: all 0.5s ease-in-out;
      color: white;
      p {
        color: white;
      }
    }
  }
  p {
    margin: 0;
  }
  span {
    transition: all 0.5s ease-in-out;
  }
}

.barChartArea {
  cursor: pointer;
  transition: all 0.5s ease-in-out;
  border: 1px solid #d8dce6;
  margin: 10px;
  padding: 10px;
  border-radius: 10px;
  &.activeChart {
    border-color: #ee556a;
    // background-color: #ee556a;
    box-shadow: 2px 9px 33px -8px rgba(41, 50, 66, 0.69);

    span {
      transition: all 0.5s ease-in-out;
    }
  }
  p {
    margin: 0;
  }
}

.flex-row {
  display: flex;
  flex-direction: row;
}

.flrt {
  float: right !important;
}

.trt {
  text-align: right !important;
}

.cash {
  text-align: right !important;
  input {
    text-align: right !important;
  }

  .editable-cell-value-wrap {
    text-align: right !important;
  }
}

.colon {
  &:after {
    content: ":";
  }
}

.txtRight {
  text-align: right;
}

.image_container {
  img {
    width: 50px;
  }
}

.EquipmentTag {
  margin-bottom: 3px;
  text-overflow: ellipsis;
  max-width: 120px;
  overflow: hidden;
}
input {
  text-transform: capitalize !important;
}

.disabledCard {
  cursor: no-drop;
  position: absolute;
  width: 100%;
  height: 100%;
  background: transparent;
}

.cursor-notallowed {
  cursor: not-allowed !important;
}
.disabled-link {
  pointer-events: none;
}

.loginContainer {
  .login-form {
    input {
      text-transform: none !important;
    }
  }
}

.caps {
  text-transform: capitalize !important;
}

.upperCase {
  text-transform: uppercase !important;
}

.no-x {
  .ant-modal-content {
    // border-radius: 0;
    .ant-modal-close {
      display: none !important;
    }
    .ant-modal-close-x {
      display: none !important;
    }
  }
}

/* tile uploaded pictures */
.upload-list-inline .ant-upload-list-item {
  float: left;
  width: 200px;
  margin-right: 8px;
}
.upload-list-inline .ant-upload-animate-enter {
  animation-name: uploadAnimateInlineIn;
}
.upload-list-inline .ant-upload-animate-leave {
  animation-name: uploadAnimateInlineOut;
}

.err {
  border-color: #ee556a !important;
  .ant-select-selection {
    border-color: #ee556a !important;
  }
  .ant-calendar-picker-input {
    border-color: #ee556a !important;
  }
}

.ellipsis {
  display: inline-block;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 100%;
}

.white {
  color: white !important;
}

.card-default-style {
  border: none;
  text-align: left;
  max-height: 100px;
  min-height: 100px;
  overflow: hidden;
  padding: 5px;
  padding-top: 10px;
  border-radius: 6px;
  box-shadow: 0 1px 4px -1px rgba(0, 0, 0, 0.2);
}

.flexCenter {
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
}

.projectsSkeleton {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  // box-shadow: 0 1px 4px -1px rgba(0, 0, 0, 0.2);
  padding: 0px 5px;
}

.alertTooltip {
  svg {
    margin: 0 !important;
  }
}

.schedulerTagContainer {
  .ant-form-item-children {
    display: flex;
    flex-wrap: wrap;
  }
  .schedulerTag {
    background: #ee556a !important;
    border-radius: 15px !important;
    padding: 3px 10px !important;
    border: none !important;
    color: white !important;
    margin: 0 1px 3px 0 !important;
  }
}

.notificationCommaContainer {
  margin: 0;
  display: inline;
  span {
    font-weight: bold;
    font-size: 1rem;
    color: #ee556a;
    cursor: pointer;
    &::after {
      content: ", ";
    }
    &:last-child::after {
      content: "";
    }
  }
}

.loaderContainer {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1;
}

.rdl-control {
  min-height: 350px !important;
  option {
    padding: 3px 5px;
  }
}

/* Document upload*/
.imageCategory {
  width: 100%;
  height: 100px;
  background-color: red;
}

.imageStyleContainer,
.documentStyleContainer {
  padding: 10px;
  position: relative;
  .imageStyle,
  .docStyle {
    width: 100%;
    height: 100px;
    // height: 200px;
    object-fit: cover;
    border-radius: 10px;
    box-shadow: 0px 0px 10px 5px #0000001c;
  }
  .docStyle {
    object-fit: contain;
    height: 30px;
  }
  .deleteIcon {
    position: absolute;
    z-index: 1;
    top: -3px;
    right: 0px;
    background: #f86f6f;
    color: white;
    padding: 2px 6px;
    border-radius: 8px;
    box-shadow: 0px 0px 10px 5px #0000001c;
  }
}

.desc {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.documentStyleContainer {
  .documentStyle {
    border-radius: 8px;
    padding: 10px;
    box-shadow: 0px 0px 10px 5px #0000001c;
    .docStyle {
      border-radius: 0;
      box-shadow: none;
    }
    .deleteIcon {
      top: -10px;
      right: -10px;
    }
  }
}

.invoice {
  .imageCategory {
    width: 100%;
    height: 100px;
    background-color: red;
  }

  .imageStyleContainer,
  .documentStyleContainer {
    padding: 10px 10px 10px 0;
    position: relative;
    .imageStyle,
    .docStyle {
      width: 100%;
      height: 30px;
      // height: 200px;
      object-fit: cover;
      border-radius: 10px;
      box-shadow: 0px 0px 10px 5px #0000001c;
    }
    .docStyle {
      object-fit: contain;
    }
    .deleteIcon {
      height: 25px;
      display: flex;
      align-items: center;
      justify-content: center;
      position: absolute;
      z-index: 1;
      top: -3px;
      right: 0px;
      background: #f86f6f;
      color: white;
      padding: 2px 6px;
      border-radius: 8px;
      box-shadow: 0px 0px 10px 5px #0000001c;
    }
  }

  .desc {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .documentStyleContainer {
    .documentStyle {
      border-radius: 8px;
      padding: 10px;
      box-shadow: 0px 0px 10px 5px #0000001c;
      .docStyle {
        border-radius: 0;
        box-shadow: none;
      }
      .deleteIcon {
        height: 25px;
        display: flex;
        align-items: center;
        justify-content: center;
        top: -10px;
        right: -10px;
      }
    }
  }
}

.highLightClass {
  background-color: #ee556a;
  color: white;
}

.tooltipBullet {
  margin: 0;
  padding-left: 20px;
}

.carousalButton {
  border-radius: 50%;
  cursor: pointer;
  transition: all 0.3s ease-in-out;
  width: 30px;
  height: 30px;
  outline: none;
  background: transparent;
  color: black;
  position: absolute;
  border: 0;
  &:hover {
    background: #ee556a;
    color: white;
    // border: 1px solid #ee556a;
  }
  &:nth-child(1) {
    left: -16px;
  }
}
.dashboardCarousal {
  .slider-control-bottomcenter {
    display: none;
  }
}

.noProjectArea {
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 10%;
  .noProject {
    font-size: 1.5rem !important;
    font-weight: bold !important;
  }
  p {
    font-size: 1.5rem !important;
    font-weight: bold !important;
    color: #ee556a;
    margin-bottom: 12px;
  }
}

.linear-wipe {
  text-align: center;
  font-size: 2rem;
  background: linear-gradient(
    to right,
    #fff 20%,
    #ee556a 40%,
    #ff0 60%,
    #fff 80%
  );
  background-size: 200% auto;

  color: #000;
  background-clip: text;
  text-fill-color: transparent;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;

  animation: shine 2s linear infinite;
  @keyframes shine {
    to {
      background-position: -200% center;
    }
  }
}

.accessDenied {
  .accessDeniedText {
    font-weight: bold !important;
    font-size: 2rem !important;
  }
}

.attendanceCardContainer {
  padding: 10px;
  .attendanceCard {
    box-shadow: 0 14px 28px rgba(0, 0, 0, 0.25), 0 10px 10px rgba(0, 0, 0, 0.22);
  }
}

#email-div {
  input {
    text-transform: lowercase !important;
  }
}

.font-weight-bold {
  font-weight: bold;
}

.mt-10 {
  margin-top: 10px;
}

.ant-collapse .ant-collapse-item .ant-collapse-header {
  padding: 10px !important;
}

.ant-tabs-bar {
  margin-bottom: 0px !important;
}

.ant-form-item {
  margin: 0 0 10px;
}

.ant-tabs .ant-tabs-top-content.ant-tabs-content-animated,
.ant-tabs .ant-tabs-bottom-content.ant-tabs-content-animated {
  padding-top: 5px;
}
