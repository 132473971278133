.map-wrapper {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 10;
  .header {
    padding: 0px 10px;
    background-color: #001628;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0px 10px;
    #title {
      color: #fff;
      font-size: 1.1rem;
    }
  }
  .map-main-div {
    min-height: 80%;
    width: 100%;
    padding: 0;
    background-color: #fff;
  }
  #loading {
    min-height: 85vh;
    background-color: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  #pac-input {
    outline: none;
    height: 30px;
    border-radius: 4px;
    border: 1px solid black;
    padding-left: 10px;
    min-width: 200px;
    margin: 20px;
  }
  #location-done-btn {
    color: #fff;
    background-color: #ee556a;
    border-color: #ee556a;
    padding: 10px 20px;
    margin: 20px;
    border: none;
    border-radius: 3px;
    height: 30px;
  }
}
