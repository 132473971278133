.labelstyle {
  font-weight: 600;
  padding-top: 7px;
  text-align: right;
}
.label {
  font-weight: 700;
  &::after {
    display: none !important;
  }
}

#email {
  text-transform: none !important;
}
