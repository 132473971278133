.tdContainer {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  overflow: hidden;
  .strip {
    position: absolute;
    top: 0;
    bottom: 0;
    width: 6%;
  }
  .childDiv {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    flex-wrap: wrap;

    justify-content: flex-start;
    //padding-left: 10px;

    // background-color: white;
  }
  .childDivDes {
    // height: 100%;
    padding: 10px 10px 10px 10px;
    text-align: left;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    // background-color: white;
  }

  &:hover ~ td {
    background-color: yellow !important;
  }
  .ant-calendar-picker:hover .ant-calendar-picker-clear {
    // display: none !important;
  }
}
