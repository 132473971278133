.multiple-upload-wrapper {
  display: flex;
  flex-direction: row;
  .new-upload {
    height: 100px;
    width: 100px;
    background-color: #fafafa;
    border: 1px dashed #d9d9d9;
    border-radius: 8px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    margin-right: 10px;
    #input {
      display: none;
    }
    .content {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      #plus-icon {
        font-size: 1.6rem;
        margin: 0px;
        padding: 0px;
        font-weight: 500;
      }
      #upload-txt {
        font-size: 1rem;
        margin: 0px;
        padding: 0px;
        font-weight: 500;
        margin-top: -15px;
      }
    }
  }
  .img-wrapper {
    margin-left: 10px;
    display: flex;
    flex-direction: row;
    gap: 10px;
    flex-wrap: wrap;
    .image-div {
      position: relative;
      img {
        height: 100px;
        width: 100px;
        object-fit: cover;
        border-radius: 8px;
        cursor: pointer;
      }
      .delete-btn {
        position: absolute;
        top: 10px;
        right: 10px;
        background-color: #ed556a;
        border-radius: 100px;
        height: 24px;
        width: 24px;
        display: flex;
        justify-content: center;
        align-items: center;
        img {
          height: 16px;
          width: 16px;
        }
      }
    }
    .moreimges {
      height: 100px;
      width: 100px;
      background-color: #fafafa;
      border: 1px dashed #d9d9d9;
      border-radius: 8px;
      display: flex;
      justify-content: center;
      flex-direction: column;
      align-items: center;
      cursor: pointer;
      #count {
        font-size: 0.8rem;
        font-weight: 400;
        margin: 0px;
        padding: 0px;
      }
      #txt {
        font-size: 0.8rem;
        font-weight: 400;
        margin: 0px;
        padding: 0px;
        margin-top: -15px;
      }
    }
  }
  .existing-wrapper {
    display: flex;
    gap: 10px;
    overflow-x: scroll;
    .main-div {
      height: 100px;
      width: 100px;
      background-color: #fafafa;
      border: 1px dashed #d9d9d9;
      border-radius: 8px;
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;
      .inner-div {
        position: relative;
        min-height: 100px;
        min-width: 100px;

        .document-div {
          height: 100px;
          width: 100px;
          display: flex;
          justify-content: center;
          align-items: center;
          flex-direction: column;
          span {
            font-size: 0.7rem;
          }
          #download {
            font-size: 0.8rem;
          }
        }
        img {
          height: 100px;
          width: 100px;
          object-fit: cover;
          border-radius: 8px;
          cursor: pointer;
        }
        .delete-btn {
          position: absolute;
          top: 10px;
          right: 10px;
          background-color: #ed556a;
          border-radius: 100px;
          height: 24px;
          width: 24px;
          display: flex;
          justify-content: center;
          align-items: center;
          img {
            height: 16px;
            width: 16px;
          }
        }
      }
    }
  }
}

.upload-wrapper-small {
  .new-upload {
    height: 60px;
    width: 60px;
  }
  .existing-wrapper {
    .main-div {
      height: 60px;
      width: 60px;
      .inner-div {
        height: 60px;
        width: 60px;
        min-height: 60px;
        min-width: 60px;
        .delete-btn {
          top: 6px;
          right: 6px;
        }
        img {
          height: 60px;
          width: 60px;
        }
        .document-div {
          height: 60px;
          width: 60px;
        }
      }
    }
  }
}
